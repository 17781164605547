<template>
  <div class="settings-row row">
    <div class="col-10 settings-col">
      <div class="settings-card mb-4">
        <div class="card-content card shadow text-center">
          <i class="bi bi-info-circle-fill"></i>
          <p>
            {{ $t('optin.env_percentage') }}
            <span> {{ env_percentage }}.</span> <br />{{
              $t('optin.ratio_full')
            }}
          </p>
        </div>
      </div>
    </div>
  </div>
  <!-- Results -->
  <div class="row user-results mb-4" v-if="results.length">
    <div
      class="col-12 col-lg-4 full-report-col"
      style="padding: 0.5rem"
      v-for="result in results"
      :key="result.position"
    >
      <report-item :engine="result.engine" :result="result" />
    </div>
  </div>
  <!-- Loading -->
  <div class="row user-results mb-4" v-if="loading">
    <div class="col-12 col-lg-4">
      <app-card>
        <template #title
          ><el-skeleton :rows="0" :loading="loading" animated
        /></template>
        <template #text
          ><el-skeleton :rows="6" :loading="loading" animated
        /></template>
      </app-card>
    </div>
    <div class="col-12 col-lg-4">
      <app-card>
        <template #title
          ><el-skeleton :rows="0" :loading="loading" animated
        /></template>
        <template #text
          ><el-skeleton :rows="6" :loading="loading" animated
        /></template>
      </app-card>
    </div>
    <div class="col-12 col-lg-4">
      <app-card>
        <template #title
          ><el-skeleton :rows="0" :loading="loading" animated
        /></template>
        <template #text
          ><el-skeleton :rows="6" :loading="loading" animated
        /></template>
      </app-card>
    </div>
  </div>
  <!-- Error -->
  <div class="row err-row mb-4" v-if="showError">
    <div class="col-12 col-lg-8 err-col">
      <app-card>
        <template #title>{{ $t('bug.title') }}</template>
        <template #text>{{ $t('bug.restricted.sub') }}</template>
        <template #button>
          <localized-link :to="{ name: 'Home' }">
            <app-button class="btn btn-primary">
              {{ $t('bug.restricted.btn') }}
            </app-button>
          </localized-link>
          <div class="contact-link">
            <app-support-link>{{ $t('bug.restricted.link') }}</app-support-link>
          </div>
        </template>
      </app-card>
    </div>
  </div>
  <download-pdf :results="results" v-if="!showError" />
</template>

<script>
// TODO add env/eco weight to results
import DownloadPdf from '@/components/pdf/DownloadPdf';
import ReportItem from '@/components/results/ReportItem';
import ResultService from '@/utils/services/ResultService';

export default {
  name: 'FullResults',
  components: {
    ReportItem,
    DownloadPdf,
  },
  data() {
    return {
      results: [],
      dbResults: [],
      showError: false,
      optin: false,
      loading: true,
      env_percentage: '',
    };
  },
  computed: {
    lang() {
      return this.$i18n.locale;
    },
  },
  async created() {
    try {
      const res = await ResultService.getResults(this.$route.params.id);

      this.optin = res.optin;

      if (this.optin) {
        this.dbResults = res.results;
        this.env_percentage = ResultService.getEnvPercentage(
          this.lang,
          res.results[0].envPercentage
        );
        this.filterResults();
      }
    } catch (_err) {
      this.showError = true;
      this.loading = false;
    }
  },
  methods: {
    filterResults() {
      this.results = ResultService.filterResults(this.lang, this.dbResults);
      const { loading, error } = ResultService.validateResults(this.results);

      this.loading = loading;
      this.showError = error;
    },
  },
};
</script>

<style lang="scss">
.err-row {
  padding-right: 2rem !important;
  padding-left: 2rem !important;
}
.err-col {
  margin: 0 auto;
  margin-bottom: 3rem;
}

.full-report-col {
  display: flex;
  justify-content: center;
  .card {
    margin-bottom: 2rem;
  }
}

.settings-row {
  margin-bottom: 3rem;
  .settings-col {
    margin: 0 auto;
    .settings-card {
      background-color: transparent;
      display: flex;
      justify-content: center;
      .card-content {
        background-color: $lightest-green;
        padding: 0.25rem 0.75rem;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        max-width: fit-content;
        p {
          font-size: 14px;
          margin-bottom: 0;
        }
        i {
          font-size: 25px;
          margin-right: 0.5rem;
          color: $secondary;
        }
      }
    }
  }
}
</style>
